/**
 *
 *  System constants
 *
 *    All messages go through the portal route
 *
 *
 *
 *
 *
 *
 */

 export default  {
  API_KEY:  'SGjQSIdvPVD1Ylzv1TTsgNnLoRwVVAGs',
  PORTAL_APIURL: (process.env.NODE_ENV === "development") ? 'http://sandbox.ioihan.com:9040/portal/1'  : 'https://api.icare.cafe/portal/1'
  // PORTAL_APIURL: (process.env.NODE_ENV === "development") ? 'http://127dev.link:9040/portal/1'  : 'https://api.icare.cafe/portal/1'
}

