<template>
  <nav class="navbar navbar-expand-md navbar-light bg-light sticky-top">
    <div class="container-fluid">
      <a class="navbar-brand" href="/home"><img class="header-logo" height="64" src="@/assets/img/icare-connect-logo-dark-512.png" alt="iCare Connect logo"></a>
      <button class="navbar-toggler" type="button" v-b-toggle.menu1 >
        <span class="navbar-toggler-icon"></span>
      </button>
      <b-collapse class="collapse navbar-collapse" id="menu1"> 
        <!-- <ul class="navbar-nav ml-auto">
          <li class="navbar-item active">
            <a class="nav-link" href="/home">Home</a>
          </li>
          <li class="navbar-item">
            <a class="nav-link" href="/profile">My Profile</a>
          </li>
          <li class="navbar-item">
            <a class="nav-link" href="/logoff">Sign Out {{userAlias()}}</a>
          </li>
        </ul> -->
        <b-nav class="ml-auto">
          <b-nav-item href="/home" active>Home</b-nav-item>
          <b-nav-item-dropdown id="user-nav" toggle-class="nav-link-custom" right :text="userAlias()">
            <b-dropdown-item href="/profile">Profile</b-dropdown-item>
            <b-dropdown-item href="/logoff">Logout</b-dropdown-item>
          </b-nav-item-dropdown>
          <div><img class="nav-profile-image" :src="userProfileImage()"></div>
        </b-nav>
      </b-collapse>
      
    </div>
  </nav>
</template>

<script>
/* eslint-disable */
import MenuDialog from './MenuDialog'

  export default {
    data() {
      return {
        showMenu: false,
        mainMenu: []
      }
    },
    components: {
      //MenuDialog
    },
    computed: {
    },
    mounted() {
      if( ! this.$root.alias) {
        let user = JSON.parse(localStorage.getItem( 'user' ));
        if( user ) {
          this.$root.user = user;
        } else {
          this.$router.push( "/login");
        }
      }
    },
  methods: {
    userAlias() {
      if( this.$root.user ) {
        return this.$root.user.alias;
      }
      return '';
    },
    userProfileImage() {
      if( this.$root.user ) {
        if( this.$root.user.pictureUrl ) {
          return this.$root.user.pictureUrl;
        } else {
          return '';
        }
      }
      return '';
    },
    isLoggedIn () { // maybe this needs to be $store as this won't repaint the menu :(
      if (localStorage.getItem('x-access-token')) {
        return true
      }
      return false
    },
    logOff () {
      localStorage.removeItem('x-access-token')
      setTimeout( this.$router.push({ name: 'login' }), 1);
    },
    showMenuDlg () {
      this.showMenu = !this.showMenu
    },
    changeRoute () {
      console.log('clicked changeRoute')
    }
  }
}
</script>

<style lang="scss">
 /* use custom.scss in assets */
</style>
