import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import About from '../views/About.vue'
// import Donations from '../views/Donations.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { authRequired: false }
  },
  {
    path     : '/login/:domain?',
    name     : 'Login',
    component: () => import( /* webpackChunkName: "login" */ '../views/Login.vue'),
    props    : true,
    meta     : { authRequired: false }
  },
  {
    path     : '/loginauto/:domain?',
    name     : 'LoginAuto',
    component: () => import( /* webpackChunkName: "login" */ '../views/LoginAuto.vue'),
    props    : true,
    meta     : { authRequired: false }
  },
  {
    path: '/portal',
    name: 'portal',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    props    : false,
    component: () => import(/* webpackChunkName: "portal" */ '../views/private/Portal.vue'),
    // component: Donations,
    meta     : { authRequired: true }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: { authRequired: false }
  },
  {
    path: '*',
    name: 'Default',
    component: Home,
    meta: { authRequired: false }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let r = router;
  const authRequired = to.matched.some(route => route.meta.authRequired);

  let token;// = null

  try {
    if (to.matched.some(route => route.meta.removeToken)) {
      console.log('******** Hit unknown route ** removing token (never looked for one)')
    } else {
      try{

        // token = this.$root.getToken('index.js');

        if(!token ) {
          token = router.getSystemToken();
        };

        if( !token) {
          token = localStorage.getItem('x-access-token');   // if we're running in an iFrame??
        }
      } catch( error ) {
        console.log( "token,error" , error);
        token = sessionStorage.getItem('x-access-token');
      }
    }
  } catch( error ) {
    console.error( error.message );
    token = null;
  }

  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);

  if (authRequired && !token ) {
    console.log('******** Hit authRequired route - no token - going to root')
    // next('/');
    next({ name: 'home' });
  } else {
    next();
  }
})

export default router
