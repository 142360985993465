<template>
  <div style=" width: 100%" class="nof-secureSite-content" >
    <img style="margin-top: 2em; width:50%;max-width:400px;" alt="iCare Connect" src="@/assets/img/icare-connect-logo-dark-512.png" />
    <hr>
    <h3>Caring Today For a Better Tomorrow</h3>
    <hr>
    <br />
    <div>
      <h1 class="nof-header">iCare Portal solution</h1>
      <div style="margin: 2em 0 3em 0; border:1px solid gray;padding:2em;border-radius:2em;max-width:600px;margin-left:auto;margin-right:auto;font-size:1.1em;">
        <ul style="text-align:left;">
          <li>Seamless integration with iCare Solution Suite</li>
          <li>Real-time transaction listing with receipt</li>
          <li>Work Order status and history</li>
          <li>Real-time news, events, announcements, and more</li>
          <li>List birthdays, phone directories, resident or employee search</li>
          <li>Connect your residents with clubs, hobbies, and shared interests</li>
          <li>Online Ordering of meals or merchandise</li>
          <li>Register for services, trips, outings, clubs, and events</li>
          <li>Central access to important documents, leases, and bylaws</li>
          <li>Can be easily integrated right into YOUR website</li>
        </ul>
      </div>  
      <p>Have a data source you want to drop into iCare Connect?  Contact us.</p>
      <p>
        For details on how to implement the iCare Portal in your organization,
        please email: <a href="mailto:info@icareConnect.me?subject=iCare Portal Information Request">info@icareConnect.me</a>
      </p>

    </div>
 
    <a href="https://www.newcare.international" target="_blank"><img style="margin-top: 2em;" height="128" alt="Newcare International" src="/img/newcare-logo-350.png" /></a>

    <div  class="mt-5" >
      <div style="width:100%;font-size:9px;">
        <a href="https://www.newcare.international" target="_blank"><span style="text-decoration:none;">Provided By Newcare International, an IOIHAN company :: Version {{$root.appVersion}} {{ $root.releaseDate }} :: (c) Copyright 2023-{{new Date().getYear()+1900}}, All Rights Reserved</span></a>
      </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import LoginCard from "@/components/LoginCard.vue"

export default {
  name: "Home",
  components: {
    // LoginCard
  },
  data() {
    return {
      message: "Welcome to iCare Connect",
      subDomain: "",
    };
  },
  created() {
    this.subDomain = this.$root.subDomain();
  },
  methods: {
    getInfo( t  ) {
      let url = 'https://www.newcare.international#icare-connect-' + t;
      window.location.href = url;
    },
    addUser(e) {
      // console.log("Home.addUser()", e);
    },
    hasSubdomain() {
      if (this.subDomain && this.subDomain !== "www") {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" >

.center-it {
        margin: 0 auto; /* Added */
        float: none; /* Added */
        margin-bottom: 10px; /* Added */
}

body {
  font-size: 12pt;
  background-repeat: repeat-y;
  background-position: 50% 0%;
  background-color: rgb(239, 238, 233);
  background-image: none;
  font-family: Lucida Sans;
  font-weight: normal;
  font-style: normal;
  padding-bottom: 1px;
}

.page-footer {
    width          : 100%;
    position       : absolute;
    bottom         : 4px;
    margin         : auto;
    justify-content: center;
    vertical-align : bottom;
    font-size      : 9px;
  }

.nof-secureSite-content {
  background-color: rgb(237, 237, 237);
  color: black;
  padding: 2px;
  border-left: 2px solid rgb(127, 186, 243);
  border-right: 2px solid rgb(127, 186, 243);
}

.nof-header {
  // color: rgb(173,199,238);
  color:#00308F;
  padding: 5px;
}

.nof-text {
  color: rgb(58, 58, 85);
}
</style>
