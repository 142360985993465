<template>
  <div id="app" >
    <!-- <Header v-if="isLoggedIn()"/> -->
    <keep-alive>
      <router-view/>
    </keep-alive> 
  </div>
</template>
<script>
import Header from './components/Header'

export default {
  components: {
    Header
  },
  data() {
    return {
      user: null
    }
  },
  mounted () {
  },
  methods: {
    // isLoggedIn() {
    //   if( localStorage.getItem('x-access-token')) {
    //     this.$root.user = JSON.parse( localStorage.getItem('user' ));
    //     return true;
    //   } else {
    //     return false;
    //   }
    // }
  }
}
</script>
<style lang="scss">

#app {
  font-family            : Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align             : center;
  color                  : #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
