<template>
  <div
    v-if="show"
    fullscreen
    persistent
    transition="fade-transition"
    class="nav-menu-content"
  >
    <div class="nav-menu nav-menu-fullscreen">
      <div class="menu-card">
        <div class="menu-card__text">
          <div>
            <img src="@/assets/img/right-arrow-black.png" @click="hideDlg" class="menu-card__back-btn" />
          </div>
          <h3 class="menu-title">Menu</h3>
        </div>
        <div class="big-spacer"></div>
        <div class="menu-card__text">
          <div>
            <div class="menu-item" v-for="route in menuRoutes" :key="'mobile-menu-route-' + route.name" @click="doChangeRoute(route.name)">
              {{ route.text }}
            </div>
            <div class="xbig-spacer"></div>
            <div class="menu-item-2 sign-out" @click="doLogOut()">
              <i class="fas fa-sign-out-alt"></i> Sign Out
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuDialog',
  props: {
    showDlg: {
      type: Boolean,
      required: true,
      default: false
    },
    showMenuDlg: {
      type: Function,
      required: true
    },
    menuRoutes: {
      type: Array,
      required: true
    },
    changeRoute: {
      type: Function,
      required: true
    },
    logOut: {
      type: Function,
      required: true
    }
  },
  computed: {
    show () {
      return this.showDlg
    }
  },
  methods: {
    toggleSH () {
      return !this.showDlg
    },
    hideDlg () {
      this.showMenuDlg()
    },
    doChangeRoute (routeName) {
      this.changeRoute(routeName)
      setTimeout(() => {
        this.hideDlg()
      }, 500)
    },
    doLogOut () {
      this.logOut()
      this.hideDlg()
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-menu-content {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: .2s cubic-bezier(.25,.8,.25,1);
  width: 100%;
  z-index: 6;
  outline: none;
  background: #fff;
}
.nav-menu {
  -webkit-box-shadow: 0 11px 15px -7px rgba(0,0,0,.2), 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12);
  box-shadow: 0 11px 15px -7px rgba(0,0,0,.2), 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12);
  border-radius: 2px;
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  -webkit-transition: .3s cubic-bezier(.25,.8,.25,1);
  transition: .3s cubic-bezier(.25,.8,.25,1);
  width: 100%;
  z-index: inherit;
  &.nav-menu-fullscreen {
    border-radius: 0;
    margin: 0;
    height: 100%;
    position: fixed;
    overflow-y: auto;
    top: 0;
    left: 0;
  }
}

.menu-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & .menu-card__text {
    padding: 16px;
    width: 100%;
  }

  & .big-spacer {
    width: 100%;
    height: 10px;
    background: #193628;
    mix-blend-mode: normal;
    opacity: 0.1;
  }

  & .xbig-spacer {
    width: 100%;
    height: 100px;
  }

  & .menu-title {
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    color: #193628;
    margin-top: 18px;
    margin-bottom: 50px;
  }

  & .menu-item {
    font-weight: normal;
    font-size: 18px;
    line-height: 19px;
    color: #3F464D;
    padding: 15px 0px;
    border-bottom: solid 1px #19362821;
    cursor: pointer;
  }

  & .menu-item-2 {
    font-weight: normal;
    font-size: 18px;
    line-height: 19px;
    color: #3F464D;
    padding: 15px 0px;
    border-top: solid 1px #19362821;
    cursor: pointer;
  }

  & .sign-out {
    color: red;
  }

}
</style>
