import Vue from 'vue'
import App from './App.vue'
import axios from "axios";
import Api from "./services/Api";
import './registerServiceWorker'
import router from './router'
import { BootstrapVue, IconsPlugin , BootstrapVueIcons } from 'bootstrap-vue'
import VueLodash from 'vue-lodash'
import _ from 'lodash'

let {version,releaseDate}     = require('./../package.json');

// This is nice in one regard and a pain in the other. good grief!
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)

library.add(fas)

Vue.config.productionTip = false

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import "@/assets/css/colors.css";
import "@/assets/css/main.css";
import '@/assets/css/custom.scss'

Vue.prototype._ = _;

Vue.use(VueLodash, { name: 'custom' , lodash: _ })

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(BootstrapVueIcons)
Vue.use(VueSweetalert2);

import wysiwyg from "vue-wysiwyg";
Vue.use(wysiwyg, {}); // config is optional. more below
import "vue-wysiwyg/dist/vueWysiwyg.css";

const host         = window.location.host;
const urlParts     = host.split('.');
const domainLength = urlParts.length;       //3; // route1.example.com => domain length = 3

let systemToken = null;

// App.config.globalProperties.append = (path, pathToAppend) => path + (path.endsWith('/') ? '' : '/') + pathToAppend;

  router.getSystemToken = function(){ return systemToken };

new Vue({
  router,
  render: h => h(App),
  data: {
    globalVar   : "wolfies globalVar",
    appVersion  : version,
    accessToken : "",
    releaseDate : releaseDate,
    host        : host,
    urlParts    : urlParts,
    domainLength: domainLength,
    tenantName  : "",
    cart        : null,
    token       : null,
    user        : null,
    account     : null,
    integration : null,
    TZ          : "America/New_York"
  },
  methods: {
    subDomain() {
      if( this.urlParts.length > 2 ) {
        return this.urlParts[0];
      }
      return "";
    },
    hasFeature( f ) {
      if( this.integration.layout.features.indexOf( f ) >= 0 ) {
        return true;
      }
      return false;
    },
    clearGlobals() {
      this.tenant        = { tenantName: "", portal: { active: false } };
      this.user          = {};
      this.users         = [];
      this.badgeList     = null;
      this.searchString  = "";
      this.authenticated = false;
      Api.token          = "";

      this.setToken(null);

      this.tenant.tenantName = "";
      sessionStorage.clear();
    },
    isLoggedIn() {
      // return this.accessToken != null;
      if( router.getSystemToken() !== null && this.account ) {
        return true;
      }
      return false;
      // return router.getSystemToken() !== null;
    },
    logout() {
      this.setToken(null);
      this.account     = null;
      this.integration = null;
    },
    setToken(token) {
      this.accessToken = token;
      systemToken      = token;
      axios.defaults.headers.common['x-access-token'] = token;

      if( token == null ) { // if null means we're removing...
        try {
          localStorage.removeItem('x-access-token')   // if we're in an iFrame, this will fail.
        } catch( error ) {

        }
      } else { 
        try {
          localStorage.setItem('x-access-token',token)   // if we're in an iFrame, this will fail.
        } catch( error ) {

        }
      }
    },
    getToken( source ) {
      return systemToken;
    },
    async login( user ) {

    },
    async integrationLogin(data) {
      let token = data["x-access-token"];
      this.setToken( token );

      this.account = data.account;

      delete data.account;
      delete data["x-access-token"];

      this.integration = Object.assign({},data);
    },
    async confirmYesNo( title , icon = "question" , cancelButtonText = "No" , confirmButtonText = "Yes" ) {
      let result = await this.$swal( {
                                            icon: icon,
                                            title: title,
                                            showCancelButton:true,
                                            cancelButtonText: cancelButtonText,
                                            confirmButtonText: confirmButtonText,
                                            allowEscapeKey: true,
                                            allowEnterKey: true
                                          });

      if( result.dismiss ) {// <iframe id="InlineFrame1" name="InlineFrame1" src="https://lutheranhaven.donations.express/donations/407001" width="1200" height="800" frameborder="0"></iframe>
        return false;
      }

      return true;
    },
    async confirm( title , icon = "question" ) {
      let result = await this.$swal( {
                                            icon: icon,
                                            title: title,
                                            showCancelButton:true,
                                            allowEscapeKey: true,
                                            allowEnterKey: true
                                          });

      if( result.dismiss ) {
        return false;
      }

      return true;
    },
    async alert( title , icon = 'success') {
      let result = await this.$swal( {
                                            icon: icon,
                                            title: title,
                                            allowEscapeKey: true
                                          });
    },
  }
}).$mount('#app')
